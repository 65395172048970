.people {
  .person {
    position: relative;
    margin-bottom: 1.5rem;
    &:after {
      content: '';
      display: block;
      height: 0.688rem;
      background-color: #1e2225;
      width: 100%;
      border-radius: 0 0 0.625rem 0.625rem;
    }
    .person-header {
      background-color: #1e2225;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 4.25rem;
      border-radius: 0.625rem 0.625rem 0px 0px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      .user {
        margin-left: 0.875rem;
        flex-grow: 1;
        img {
          width: 2.5rem;
          height: 2.5rem;
          border-radius: 100%;
          margin-right: 15px;
        }
        span {
          font-size: 1.25rem;
        }
      }
    }

    .person-row {
      background-color: #23272a;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      height: 3.125rem;
      position: relative;
      .customer {
        padding: 0 0.875rem;
        color: $text-colour;
      }
      &.in-progress {
        .customer {
          color: $blue;
        }

        &:after {
          content: '\f0da';
          font-family: 'Font Awesome 6 Pro';
          font-weight: 700;
          position: absolute;
          color: $blue;
          display: flex;
          align-items: center;
          font-size: 1rem;
          left: -0.125rem;
          height: 1.25rem;
        }
      }
    }
    .jobs-count {
      height: 100%;
      display: flex;
      align-items: center;
      .todo,
      .in-progress,
      .done {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 5.375rem;
        min-width: 5.375rem;
        padding: 0 0.875rem;
        border-left: 1px solid rgba(255, 255, 255, 0.1);
        line-height: 1.75rem;
        height: 100%;

        i {
          font-size: 1.5rem;
        }

        .number {
          font-size: 1.75rem;
          font-weight: 600;
          width: 100%;
          display: flex;
          align-items: baseline;
          justify-content: center;

          &:after {
            font-size: 0.875rem;
            font-weight: 300;
            color: #fff;
          }
        }
      }

      .todo {
        .number {
          color: $text-colour;
        }
      }
      .done {
        color: $green;
        .number {
          &.none {
            color: $red;
          }
        }

        &.all-done {
          background-color: $green;
          .number {
            color: #000;
            &:after {
              color: #000;
            }
          }
        }
      }
    }
  }
}
