.today-panel {
  display: flex;
  width: 100%;
  height: 11.75rem;
  background-color: #15191b;
  padding: 1.5rem 3.75rem 2.125rem;
  flex-direction: column;

  .today-row {
    flex-wrap: wrap;
    gap: 1.5rem;
    display: flex;
  }

  h2 {
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }

  .today-card {
    background-color: #292d30;
    margin: 0;
    flex: 1 1 0px;
    padding: 0.875rem 0.875rem 0.531rem;
    font-size: 2.125rem;
    border-radius: 0.625rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .number {
      font-size: 2.75rem;
      display: flex;
      align-items: baseline;
      line-height: 1;
      margin-bottom: 0.625rem;
      span {
        margin-left: 0.625rem;
        font-size: 0.875rem;
        color: #fff;
      }
    }
    i {
      font-size: 2.125rem;
    }
    &:first-of-type {
      margin-left: 0;
    }
    &:last-of-type {
      margin-right: 0;
    }
  }
}
