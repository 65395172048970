$nav-bg-color: #1e2225;
$orange: #fa5838;
$green: #5ac146;
$red: #e1174e;
$blue: #3dbaec;
$primary-colour: $blue;

$text-colour: #8399a2;
$primary-button-text: #333;

$panel-bg-colour: #1e2225;

.blue {
  color: $blue;
}
.red {
  color: $red;
}
.orange {
  color: $orange;
}
.green {
  color: $green;
}
.white {
  color: #fff;
}
.text-colour {
  color: $text-colour;
}
