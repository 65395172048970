.header {
  padding: 3.75rem 0 2.5rem 0;
  margin: 0 3.75rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 8.813rem;

  h1 {
    font-size: 1.25rem;
    width: 100%;
  }

  .branding {
    display: flex;
    align-items: center;
    img {
      margin-right: 1.25rem;
      height: 2.5rem;
      width: auto;
    }
  }

  .menu {
    .dropdown-toggle {
      &::after {
        content: '\f0c9';
        font-family: 'Font Awesome 6 Pro';
        font-weight: 400;
        border: none;
        vertical-align: 0 !important;
      }
    }
    .dropdown-menu {
      background-color: #3e4245;
      &:hover {
        background-color: #3e4245;
      }

      a {
        &.dropdown-item {
          color: #fff;
        }
      }

      .dropdown-item:hover,
      .dropdown-item:focus {
        background-color: #3e4245;
        color: $blue;
      }
    }
  }
}
