.error-page {
  margin-top: 60px;
  padding-bottom: 40px;

  display: flex;
  flex-direction: column;
  height: 100%;

  align-items: center;
  justify-content: center;

  img {
    width: 250px;
    padding-bottom: 20px;
  }
}
