.customer-page {
  .customer {
    position: relative;
    margin-bottom: 1.5rem;
    &:after {
      content: '';
      display: block;
      height: 0.688rem;
      background-color: #1e2225;
      width: 100%;
      border-radius: 0 0 0.625rem 0.625rem;
    }
    .customer-header {
      background-color: #1e2225;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 4.25rem;
      border-radius: 0.625rem 0.625rem 0px 0px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      padding: 0 0.875rem;
      span {
        font-size: 1.25rem;
      }
    }

    .customer-row {
      background-color: #23272a;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      height: 3.125rem;
      .job {
        padding: 0 0.875rem;
        color: $text-colour;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .status {
      height: 100%;
      display: flex;
      align-items: center;
      .todo,
      .done {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 4.125rem;
        padding: 0 0.875rem;
        border-left: 1px solid rgba(255, 255, 255, 0.1);
        line-height: 1.75rem;
        height: 100%;
      }

      .done {
        color: #000;
        background-color: $green;
      }
    }
  }
}
