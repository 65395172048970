.right-panel {
  display: flex;
  flex-basis: 26.5rem;
  min-width: 26.5rem;
  background-color: #1e2225;
  padding: 0 1.5rem;
  height: 100%;
  min-height: 100vh;
  max-height: 100vh;
  overflow: hidden;
  flex-direction: column;
  justify-content: space-between;

  .header {
    margin: 0;
    width: 100%;
    margin-bottom: 1.5rem;
  }

  .right-panel-scroll {
    overflow: auto;
    width: 100%;
    display: flex;
    flex-direction: column-reverse;

    &::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    .update-item {
      padding-bottom: 1.5rem;
      .update {
        width: 100%;
        padding: 0.875rem;
        background-color: #15191b;
        border-radius: 0.313rem;
        margin-bottom: 0.5rem;

        img {
          max-width: 100%;
          max-height: 100%;
          width: 100%;
          height: auto;
        }
      }
      .update-info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 0.875rem;
        color: $text-colour;

        .user {
          height: 1.875rem;
          width: 1.875rem;
          border-radius: 100%;
        }
      }
    }
  }
}
