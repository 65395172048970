.loader {
  display: flex;
  flex: 1 1 auto;
  width: 100%;
  min-height: 124px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px 20px 20px;
}
