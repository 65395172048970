.job-details {
  h2,
  h3 {
    font-weight: 400;

    color: #8399a2;
  }
  h2 {
    font-size: 1.25rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
    padding-bottom: 1.2rem;
    margin-bottom: 1.2rem;
  }

  h3 {
    font-size: 1.1rem;
    padding-bottom: 1rem;
  }
  .group {
    background-color: rgba(0, 0, 0, 0.12);
    margin-bottom: 0.25rem;
    border-radius: 0.25rem;
    padding: 1rem;

    .complete-due {
      font-size: 1rem;
    }
    .border-bottom {
      border-bottom: 1px solid rgba(255, 255, 255, 0.05) !important;
    }

    .assigned {
      display: inline-flex;
      align-items: center;
      background-color: #15191b;
      box-shadow: 0px 0.125rem 0.313rem 0.063rem rgb(0 0 0 / 10%);
      border-top-left-radius: 1rem;
      border-bottom-left-radius: 1rem;
      border-top-right-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
      padding: 0.188rem 0.5rem 0.188rem 0.188rem;
      margin-top: 0.5rem;
      max-width: 100%;

      img {
        width: 1.625rem;
        height: 1.625rem;
        border-radius: 100%;
        margin-right: 0.5rem;
      }
      span {
        color: $text-colour;
        max-width: 100%;
        overflow-x: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .job-item {
    margin-bottom: 1.2rem;

    &:last-of-type {
      margin-bottom: 0;
    }

    label {
      display: block;
      color: $text-colour;
      font-size: 0.85rem;
      margin-bottom: 0.3rem;
    }
    .view {
      font-size: 0.85rem;
      margin-bottom: 0.3rem;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .status-item {
    display: flex;
    align-items: center;
    padding: 0;
    border-radius: 0.313rem;
    background-color: rgba(0, 0, 0, 0.12);
    min-height: 5.188rem;

    .i-wrapper {
      background-color: #15191b;
      padding: 0 1rem;
      display: flex;
      height: 100%;
      border-radius: 0.313rem 0 0 0.313rem;
      min-width: 4.625rem;
      justify-content: center;
      align-items: center;
    }

    i {
      font-size: 3rem;
      margin: 0.5rem 0 0.5rem;
      font-family: 'Font Awesome 6 Pro';
      font-weight: 300;
      line-height: 1;
      font-style: normal;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &.complete {
      i {
        &:before {
          content: '\f00c';
          color: $green;
        }
      }
    }

    &.not-started {
      i {
        &:before {
          content: '\f252';
          color: $red;
        }
      }
    }

    &.active {
      i {
        &:before {
          content: '\f254';
          color: $blue;
        }
      }
    }
  }

  .attachment {
    display: flex;
    justify-content: space-between;
    padding: 0.25rem 0.625rem;
    background-color: rgba(106, 122, 140, 0.2);
    cursor: pointer;
    border-radius: 0.25rem;
    margin-top: 0.5rem;

    &:hover {
      background-color: rgba(106, 122, 140, 0.3);
    }
    .type {
      font-size: 0.875rem;
      color: $text-colour;
    }
  }

  .history-item {
    margin-bottom: 1.2rem;

    .a {
      cursor: pointer;
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    img {
      margin: 0.5rem 0.5rem 0.5rem 0;
    }

    .date {
      font-size: 0.775rem;
      color: $text-colour;
      border-top: 1px solid rgba(255, 255, 255, 0.05) !important;
      padding-top: 0.5rem;
    }
  }
}
