body,
#root {
  min-height: 100vh;
}

main {
  width: 100%;
  display: flex;
  min-height: 100vh;
}
.content-wrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.content {
  padding: 1.5rem 3.75rem 1.5rem 3.75rem;
  overflow: auto;
  flex-grow: 1;
  flex-basis: 0px;
  position: relative;

  .nav {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    left: 0;
    right: 0;
    justify-content: space-between;
    pointer-events: none;

    .nav-btn {
      display: flex;
      width: 3.2rem;
      height: 3.2rem;
      align-items: center;
      justify-content: center;
      background-color: #464c4e;
      pointer-events: all !important;
      cursor: pointer;

      &:before {
        font-family: 'Font Awesome 6 Pro';
        color: #fff;
        font-size: 1.5rem;
      }

      &.prev {
        border-radius: 0 0.5rem 0.5rem 0;
        &:before {
          content: '\f104';
        }
      }

      &.next {
        border-radius: 0.5rem 0 0 0.5rem;
        &:before {
          content: '\f105';
        }
      }
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.progressBar.animate {
  div {
    transition: width 1s linear;
  }
}
