@charset "utf-8";
// =============================================================================
// Table overrides
// =============================================================================

.table-overflow {
  overflow-x: auto;
}

.table-wrapper {
  overflow-x: auto;
  max-width: 100%;
  padding-bottom: 0.625rem;
}

.table {
  text-align: left;

  background-color: $panel-bg-colour;
  box-shadow: 0px 0.125rem 0.313rem 0.063rem rgba(0, 0, 0, 0.1);

  border-radius: 0.25rem;

  color: #bbbbbb;

  &.table-fixed {
    table-layout: fixed;
  }

  & > :not(caption) > * > * {
    border-bottom-width: 0px;
  }

  tr {
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
    border-width: 1px;
    border-top: none;
    border-left: none;
    border-right: none;
  }

  &.table-responsive {
    font-size: 1.2rem;
  }
  tbody > tr {
    &:last-of-type {
      border-bottom: none;
      border-width: 0;
    }

    td,
    th {
      vertical-align: top;
    }

    .orderNo,
    .location {
      color: #fff;
    }

    .location {
      margin-top: 0.188rem;
      &:before {
        content: '\f3c5';
        font-family: 'Font Awesome 6 Pro';
        font-weight: 300;
        margin-right: 0.313rem;
        color: $blue;
      }
    }

    .desc,
    .job-comment {
      color: $text-colour;
    }
    .job-comment {
      font-style: italic;
    }

    .label {
      color: #fff;
      padding-right: 0.938rem;
      display: inline;

      span {
        color: $blue;
        display: inline-flex;
        justify-content: center;
        width: 1.25rem;
        margin-right: 0.5rem;
      }

      &.scheduled {
        span {
          color: $green;
        }
      }
    }
  }

  thead {
    th {
      background-color: rgba(0, 0, 0, 0.12);
      white-space: nowrap;
      padding-right: 1.25rem !important;
    }
    th.sortable,
    th.sorted_asc,
    th.sorted_desc {
      background-color: #15191b;
      padding: 1.125rem 0.625rem;
    }
  }

  &.td-align-top {
    tbody > tr {
      td {
        vertical-align: top;
      }
    }
  }

  &.table-v-stripes {
    tbody {
      td:nth-child(even) {
        background: rgba(0, 0, 0, 0.1);

        &.sorted {
          background-color: #16beef0f;
          &:hover {
            background-color: #26282b;
          }
        }
      }
    }
  }

  th {
    font-weight: 700;
  }

  th,
  td {
    padding: 0.563rem 0.625rem;

    &.sorted,
    &.sortable,
    &.sorted_desc,
    &.sorted_asc,
    &.sorted_desc_disabled,
    &.sorted_asc_disabled {
      &.text-right.num {
        padding: 0.563rem 1.125rem 0.563rem 0.625rem;
      }
    }
  }

  thead {
    &.row-has-buttons {
      th,
      td {
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
      }
    }

    > tr {
      th {
        //background-color: @table-head-bg-colour;

        &.sorted_asc,
        &.sorted_desc {
          background-color: $primary-colour;
          color: $primary-button-text;
          cursor: pointer;
        }
      }
    }
  }

  &.table-has-buttons {
    tbody > tr {
      th,
      td {
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
      }
    }
  }

  tbody > tr {
    height: 3.313rem;

    cursor: pointer;
    &:hover {
      background-color: #1a1d20;
    }

    th,
    td {
      .alert-danger & {
        border-top-color: $red;
      }
    }

    td {
      &.sorted {
        background-color: rgba(45, 51, 58, 0.5);
        &:hover {
          background-color: rgba(22, 190, 239, 0.04);
        }
      }

      .num,
      &.num {
        font-family: monospace;
      }
    }
  }

  input[type='checkbox'] {
    margin: 0;
    vertical-align: middle;
  }

  .btn-link {
    padding: 0;
    color: #000;
  }

  .alert & {
    margin-bottom: 0;
  }

  & > :not(:first-child) {
    border-top: none;
  }
}
.no-wrap {
  white-space: nowrap;
}
.alert.alert-danger table tbody th,
.alert.alert-danger table tbody td {
  border-top-color: rgba(255, 255, 255, 0.25) !important;
}

.table a:not(.btn) {
  color: $primary-colour;
  text-decoration: none;
  font-weight: 500;
}

table a.btn {
  font-weight: normal;
}

.highlight {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: -0.25rem;
    left: -10%;
    z-index: 10;
    width: 120%;
    height: 150%;
    background-color: fade(#46b29d, 20%);
    border: 2px solid fade(#46b29d, 40%);
    opacity: 0.1;
    border-radius: 0.25rem;
    animation: colorPulse 1s infinite linear;
  }
}

@keyframes colorPulse {
  0% {
    transform: scale(0);
    opacity: 0;
  }

  8% {
    transform: scale(0);
    opacity: 0;
  }

  15% {
    transform: scale(0.1);
    opacity: 1;
  }

  30% {
    transform: scale(0.5);
    opacity: 1;
  }

  100% {
    transform: scale(1.3);
    opacity: 0;
  }
}

.proforma-warning {
  td {
    color: #fff;
    background-color: #16beef;
    border-color: #fff !important;

    &:first-child:before {
      color: #fff !important;
    }

    a {
      color: #fff !important;
    }

    .btn-primary {
      color: #16beef;
      background-color: #fff;
    }
  }
}

.table-filter {
  display: none;
}

tr {
  background-image: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(255, 255, 255, 0.1) 0.01%,
    rgba(255, 255, 255, 0) 0.02%,
    rgba(255, 255, 255, 0) 25%,
    rgba(255, 255, 255, 0) 75%,
    rgba(255, 255, 255, 0.1) 75%,
    rgba(255, 255, 255, 0.1) 99.99%,
    rgba(0, 0, 0, 0) 100%
  );
  background-size: 200% auto;
  background-position-x: 49%;
}

._row_click {
  // this is only a target for JS
  &._row_click_added {
    td {
      cursor: pointer;
    }

    tr.clicked-navigating {
      animation: responseslide 0.5s ease;
    }

    .clicked-target {
      text-decoration: none;
    }
  }
}

.elapsed-time-label {
  white-space: nowrap;
  i {
    margin-right: 0.25rem;
    &.fa-calendar-times {
      color: #fe5557;
    }

    &.fa-exclamation-triangle {
      color: #b28222;
    }

    &.fa-calendar-check {
      color: #46b29d;
    }
  }
}

table.dataTable thead .sortable {
  background-repeat: no-repeat;
  background-position: center right;
  position: relative;
  cursor: pointer;
  &:hover {
    background-color: #1e2225;
  }

  &:after {
    position: absolute;
    font-family: 'Font Awesome 6 Pro';
    font-weight: 500;
    top: 50%;
    right: 0.313rem;
  }

  &:hover {
    &:after {
      transform: translateY(-58%);
      content: '\f30c';
      opacity: 0.6;
    }
  }
}

table.dataTable thead .sorted_asc {
  position: relative;

  &:after {
    position: absolute;
    font-family: 'Font Awesome 6 Pro';
    font-weight: 500;
    top: 50%;
    right: 0.313rem;
    transform: translateY(-58%);
    content: '\f30c';
    color: $primary-button-text;
  }
}

table.dataTable thead .sorted_desc {
  position: relative;

  &:after {
    position: absolute;
    font-family: 'Font Awesome 6 Pro';
    font-weight: 500;
    top: 50%;
    right: 0.313rem;
    transform: translateY(-58%);
    content: '\f309';
    color: $primary-button-text;
  }
}

.table-text-ellipsis div {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.datatables-bottom {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.pagination {
  margin-bottom: 0px !important;
  .paginate_button {
    button {
      display: inline-block;
      font-weight: 400;
      line-height: 1.5;
      text-align: center;
      text-decoration: none;
      vertical-align: middle;
      cursor: pointer;
      -webkit-user-select: none;
      user-select: none;
      background-color: transparent;
      border: 1px solid transparent;
      padding: 0.375rem 0.75rem;
      font-size: 1rem;
      border-radius: 0.25rem;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      color: #fff;
      background-color: #6c757d;
      border-color: #6c757d;
      margin-left: 0.313rem;

      &:hover {
        color: #fff;
        background-color: #5c636a;
        border-color: #565e64;
      }
    }
    &.active {
      button {
        background-color: $primary-colour;
        border-color: $primary-colour;
      }
    }
  }
}
.dataTables_length {
  margin-right: 0.938rem;
  select {
    &.form-control {
      background-color: #44494e !important;
      border-color: #44494e !important;
    }
  }
}
.dataTables_info {
  margin-right: auto;
}

.wp-10 {
  width: 10%;
}

.wp-30 {
  width: 30%;
}
