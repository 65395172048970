.modal-backdrop {
  background-color: #0e0f12;
}

.modal-backdrop.show {
  opacity: 0.7;
}
.modal-content {
  background-color: $body-bg;

  .modal-header {
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  }

  h4 {
    font-size: 1.375rem;
    font-weight: 400;
  }

  .btn-close {
    background: none;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    &::after {
      content: '\f00d';
      font-family: 'Font Awesome 6 Pro';
      font-weight: 300;
      color: #fff;
    }
  }
}

// ===========================================================
//  Had to add this so we could stack the modals
//  https://stackoverflow.com/a/69704736/1264533
div[role='dialog'][aria-modal='true']:nth-last-child(1) {
  z-index: 1125;
}
.modal-backdrop.show:nth-last-child(2) {
  z-index: 1100;
}
div[role='dialog'][aria-modal='true']:nth-last-child(3) {
  z-index: 1075;
}
.modal-backdrop.show:nth-last-child(4) {
  z-index: 1050;
}
div[role='dialog'][aria-modal='true']:nth-last-child(5) {
  z-index: 1025;
}
.modal-backdrop.show:nth-last-child(6) {
  z-index: 1000;
}
// ===========================================================

.comments-modal {
  .form-control {
    min-height: 30vh;
  }
}

.modal-md {
  @media (min-width: 576px) {
    max-width: 31.25rem;
  }
}
.modal-lg {
  @media (min-width: 992px) {
    max-width: 50rem;
  }
}

.modal-xl {
  @media (min-width: 1200px) {
    max-width: 90rem;
  }
}
